import { useMediaQuery } from "react-responsive";
// Based in part on: https://www.w3schools.com/howto/howto_css_media_query_breakpoints.asp

export const breakpoints = {
  /** Extra small devices (phones, 360px and down) */
  xxs: "@media only screen and (max-width: 360px)",
  /** Extra small devices (phones, 480px and down) */
  xs: "@media only screen and (max-width: 480px)",
  /** Small devices (portrait tablets and large phones, 768px and down) */
  sm: "@media only screen and (max-width: 768px)",
  /** Medium devices (landscape tablets, 1024px and down) */
  md: "@media only screen and (max-width: 1024px)",
  /** Large devices (laptops/desktops, 1280px and down) */
  lg: "@media only screen and (max-width: 1280px)",
  /** Extra large devices (large laptops and desktops, 1200px and up) */
  xl: "@media only screen and (min-width: 1200px)",
};

/** Extra large devices (large laptops and desktops, 1200px and up): `breakpoints.xl` */
export function useIsLargeDesktopAndAbove() {
  return useMediaQuery({
    query: breakpoints.xl.replace("@media only screen and ", ""),
  });
}

/** Large devices (laptops/desktops, 1200px and down): `breakpoints.lg` */
export function useIsDesktopAndBelow() {
  return useMediaQuery({
    query: breakpoints.lg.replace("@media only screen and ", ""),
  });
}

/** Medium devices (landscape tablets, 992px and down): `breakpoints.md` */
export function useIsTabletAndBelow() {
  return useMediaQuery({
    query: breakpoints.md.replace("@media only screen and ", ""),
  });
}

/** Small devices (portrait tablets and large phones, 768px and down): `breakpoints.sm` */
export function useIsLargeMobileAndBelow() {
  return useMediaQuery({
    query: breakpoints.sm.replace("@media only screen and ", ""),
  });
}

/** Extra small devices (phones, 480px and down): `breakpoints.xs` */
export function useIsMobileAndBelow() {
  return useMediaQuery({
    query: breakpoints.xs.replace("@media only screen and ", ""),
  });
}

/** Extra small devices (phones, 360px and down): `breakpoints.xxs` */
export function useIsTinyMobileAndBelow() {
  return useMediaQuery({
    query: breakpoints.xxs.replace("@media only screen and ", ""),
  });
}
