import React from "react";

import "./CardStore.scss";

import Picture from "components/Picture";

import appleStore from "assets/images/icons/apple.png";
import googleStore from "assets/images/icons/google.png";

export default function CardStore({ type = "normal", children, appearance }) {
  return (
    <div
      className={`card-store ${type && "card-store--" + type} ${appearance}`}
    >
      {children}

      <div className="card-container card-container--store">
        <h3 className="card-store-title">Available at</h3>
        <div className="btn-container">
          <div>
            <a
              href="https://apps.apple.com/us/app/loanpal-pros/id1494023388"
              target="_blank"
              rel="noopener noreferrer"
              text="Download the loanpal app for iOS"
              aria-label="Download the loanpal app for iOS"
              aria-describedby="Open the Apple's AppStore  in a new Tab"
            >
              <Picture
                className="card-store-btn"
                src={appleStore}
                width="176"
                height="52"
                alt="appleStore"
                type="clickable"
              />
            </a>
          </div>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.loanpal.loanpal_pros"
              target="_blank"
              rel="noopener noreferrer"
              text="Download the loanpal app for Android"
              aria-label="Download the loanpal app for Android"
              aria-describedby="Open the Google's PlayStore in a new Tab"
            >
              <Picture
                className="card-store-btn"
                src={googleStore}
                width="176"
                height="52"
                alt="googleStore"
                type="clickable"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
